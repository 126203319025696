div.intro-outer div.intro-bio {
    width: 60%;
    padding-top: 4rem;
    padding-left: 2rem;
    /* padding-right: 2rem; */
}

div.intro-outer div.intro-bio h1 {
    font-size: 3rem;
    margin-bottom: 0rem;
    animation: slidein-vertical 0.75s linear calc(1);
    animation-delay: 0ms;
}

div.intro-outer div.intro-bio p {
    margin-top: 0rem;
    width: 90%;
    animation: slidein-vertical 0.75s linear calc(1);
    animation-delay: 100ms;
}


div.intro-outer div.intro-info {
    width: 100%;
    padding: 1.5rem 0;
}

div.intro-outer div.intro-info section.status{
    display: flex;
    flex-direction: column;
    height: 4rem;
    justify-content: space-around;
}

div.intro-outer div.intro-info section.status span{
    animation: slidein-vertical 0.75s linear calc(1);
    animation-delay: 200ms;
}

div.intro-outer div.intro-info section.links{
    margin: 2.5rem 0;
}

div.intro-outer div.intro-info section.links span a .links-icon{
    animation: slidein-vertical 0.75s linear calc(1);
    animation-delay: 300ms;
}

@keyframes slidein-vertical {
    from {
        transform: translateX(10rem);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slidein-horizontal {
    from {
        transform: translateY(5rem);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

div.intro-outer div.profile-picture-section {
    width: 40%;
    padding: 4.3rem 0;
    align-items: center;
    justify-items: left;
}

@media only screen and (max-width: 1095px) {

    div.intro-outer div.profile-picture-section {
        padding-right: 6%;
    }
}

@media only screen and (max-width: 1018px) {

    div.intro-outer div.profile-picture-section {
        padding-right: 10%;
    }
}

@media only screen and (max-width: 885px) {

    div.intro-outer div.profile-picture-section {
        padding-right: 14%;
    }
}

@media only screen and (max-width: 832px) {

    div.intro-outer div.profile-picture-section {
        padding-right: 22%;
    }
}

@media only screen and (max-width: 731px) {
    div.intro-outer div.profile-picture-section {
        padding-right: 26%;
    }
}

div.intro-outer div.profile-picture-section img.profile-picture {
    width: 20rem;
    height: 20rem;
    position: relative;
    object-fit: cover;
    z-index: 2;
    left: 50px;
    top: 0px;
    animation: slidein-horizontal 0.75s linear calc(1);
}

div.intro-outer div.profile-picture-section div.backshadow-white {
    width: 20rem;
    height: 20rem;
    /* background-image: url('../../assets/bg-intro-bright.jpeg'); */
    background-color: white;
    overflow: hidden;
    position: relative;
    top: -30px;
    left: -30px;
}

div.intro-outer div.profile-picture-section div.backshadow-gray {
    width: 20rem;
    height: 20rem;
    background-color: #e1ede9;
    overflow: hidden;
    z-index: 1;
    position: relative;
    bottom: 280px;
    left: 95px;
    animation: slidein-horizontal 0.75s linear calc(1);
}

h1.typewriter {
    align-self: left;
    overflow: hidden; 
    text-align: left;
    border-right: solid black;
    white-space: nowrap;
    /* margin: 0 auto; */
    /* letter-spacing: ; */
    max-width: fit-content;
    animation:
    typing 3.5s steps(40, end),
    blink-cursor .75s step-end infinite;
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

@keyframes blink-cursor {
    from, to { border-color: transparent }
    50% {border-color: black}
}

@media only screen and (max-width: 700px) {
    /* div.intro-outer {
        
    } */

    div.intro-outer div.intro-bio {
        padding-top: 1rem;
        padding-left: 0;
        text-align: left;
        width: 100%;
    }

    div.intro-outer div.intro-info {
        width: 100%;
        padding: 1.5rem 0;
    }

    div.intro-outer div.profile-picture-section {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    div.intro-outer div.profile-picture-section img.profile-picture {
        left: 0;
    }   

    div.intro-outer div.profile-picture-section div.backshadow-gray {
        left: 45px;
    }

    div.photo-backshadow-container {
        height: 35vh;
        position: relative;
        left: 3%;
    }
}
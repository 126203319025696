@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap');


div.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4rem;
    background-color: transparent;
    border-width: 2px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
}

div img.logo {
    width: 3rem;
    height: 3rem;
    animation: rotation 5s infinite linear;
    padding: 0.5rem 4rem;
}

div h1.logo-name {
    color: white;
    /* align-self: center; */
    padding-bottom: 0.5rem;
    /* padding-left: 4.5rem; */
    margin-left: min(4.5rem, 2.5%);
    font-family: 'Anonymous Pro'; 
}

div h1.line {
    position: relative;
    height: 32%;
    width: 32%;
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 175%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    transform: translateX(10px);    
}

.anim-typewriter{
    animation: typewriter 2s steps(16) 1 normal both,
               blinkTextCursor 800ms steps(16) infinite normal;
}

@keyframes typewriter{
    from{width: 0;}
    to{width: 15.25rem;}
}
@keyframes blinkTextCursor{
    from{border-right-color: rgba(255,255,255,.75);}
    to{border-right-color: transparent;}
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

div ul.shortcuts-options-navbar {
    list-style-type: none;
    display: flex;
    width: 55%;
    justify-content: flex-end;
    padding-right: min(4rem, 2.5%);
}

div ul.shortcuts-options-navbar li {
    font: white;
    margin-right: 15px;
}

.menu-icon-clickable {
    background-color: transparent;
    border: none;
    margin-right: 2.5%;
}

.menu-icon {
    width: 25px;
}


.school-pic {
    border-radius: 50%;
    width: 12rem;
    height: 12rem;
}

/* .about-outer {
    background: black;
} */

.left {
    align-self: start;
}

.right {
    align-self: end;
}

/* .headline {
    font: 25px;
} */
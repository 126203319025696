html {
  scroll-behavior: smooth;
  /* min-height: 100%; */
  /* background-image: url('./assets/bg-intro-bright.jpeg'); */
  /* position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; */
}

.bg-image {
  background-image: url('./assets/bg-intro-bright.jpeg');
  overflow: hidden;
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

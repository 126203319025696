div.skills-outer {
    display: flex;
    flex-direction: row;
    padding: 8rem 5rem;
    /* height: 31rem; */
}

.slider-content {
    height: 30rem;
    /* display: flex-start;
    justify-content: start; */
}